import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { SharedService } from './shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./shared.service";
var FileDataService = /** @class */ (function () {
    function FileDataService(http, sharedService) {
        this.http = http;
        this.sharedService = sharedService;
    }
    FileDataService.prototype.getInitContent = function (scope) {
        var dnn = this.sharedService.dnn || '';
        var url = environment.api.baseUrl + "/actions/repository/files/" + scope;
        if (dnn !== '') {
            url += "?dnn=" + dnn;
        }
        return this.http.get(url);
    };
    FileDataService.prototype.getContents = function (folder) {
        if (folder === void 0) { folder = null; }
        return this.http.post(environment.api.baseUrl + "/actions/repository/file/list", { folder: folder });
    };
    FileDataService.prototype.getBrokerTemplates = function () {
        return this.http.get(environment.api.baseUrl + "/actions/repository/file/templates");
    };
    FileDataService.prototype.getClientDocuments = function () {
        var dnn = this.sharedService.dnn || '';
        return this.http.get(environment.api.baseUrl + "/actions/repository/file/documents/" + dnn);
    };
    FileDataService.prototype.downloadFile = function (file) {
        var data = {
            'name': file.name,
            'fileId': file.id,
            'mime': file.mime
        };
        return this.http.post(environment.api.baseUrl + "/actions/repository/file/download", data, { responseType: 'blob' });
    };
    FileDataService.prototype.uploadFile = function (file, currentFolder, scope) {
        var dnn = this.sharedService.dnn || '';
        var formData = new FormData();
        formData.append('folder', currentFolder);
        if (dnn !== '') {
            formData.append('dnn', dnn);
        }
        formData.append('scope', scope);
        formData.append('files', file, file['name']);
        return this.http.post(environment.api.baseUrl + "/actions/repository/file/upload", formData);
    };
    FileDataService.ngInjectableDef = i0.defineInjectable({ factory: function FileDataService_Factory() { return new FileDataService(i0.inject(i1.HttpClient), i0.inject(i2.SharedService)); }, token: FileDataService, providedIn: "root" });
    return FileDataService;
}());
export { FileDataService };
