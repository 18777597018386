import { HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SpinnerService, SharedService } from './../services';
import { environment } from './../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../services/spinner.service";
import * as i2 from "../services/shared.service";
var ApiKeyInterceptor = /** @class */ (function () {
    function ApiKeyInterceptor(spinnerService, sharedService) {
        this.spinnerService = spinnerService;
        this.sharedService = sharedService;
    }
    ApiKeyInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        this.spinnerService.setVisibility(true);
        var token = this.sharedService.token;
        return next.handle(request.clone({
            setHeaders: {
                'api-key': environment.api.key,
                'Authorization': "Bearer " + token,
                'Cache-Control': 'no-cache'
            }
        }))
            .pipe(map(function (event) {
            if (event instanceof HttpResponse) {
                _this.spinnerService.setVisibility(false);
            }
            return event;
        }), catchError(function (error) {
            _this.spinnerService.setVisibility(false);
            return throwError(error);
        }));
    };
    ApiKeyInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function ApiKeyInterceptor_Factory() { return new ApiKeyInterceptor(i0.inject(i1.SpinnerService), i0.inject(i2.SharedService)); }, token: ApiKeyInterceptor, providedIn: "root" });
    return ApiKeyInterceptor;
}());
export { ApiKeyInterceptor };
