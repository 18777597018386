<mat-toolbar class="toolbar-title">
  <button mat-mini-fab
    color="success"
    aria-label="Back"
    (click)="navigateUp()"
    *ngIf="canNavigateUp">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <span [className]="canNavigateUp ? 'left-space' : 'main-folder'"> {{ alias || path}} </span>
</mat-toolbar>

<mat-card-content>

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name" style="width: 27%;"> File name </th>
      <td mat-cell
        *matCellDef="let element"
        [className]="'mat-cell cell-content '+ (element.isFolder ? 'folder' : 'file')"
        (click)="element.isFolder ? navigate(element) : false">
        <span [className]="'mdi mdi-' + getIcon(element.mime)"></span>
        <div class="spacer"></div>
        {{element.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 1%;"> Actions </th>
      <td mat-cell *matCellDef="let element" class="actions">
        <ng-template [ngIf]="!element.isFolder">
          <button mat-icon-button class="mdi mdi-open-in-new action"
            [ngClass]="{'mdi_disable_icon': (!element.name.includes('pdf'))}"
            [matTooltip]="showTooltip(!element.name.includes('pdf'))"
            (click)="viewFile(element)"></button>
          <button mat-icon-button class="mdi mdi-cloud-download-outline action" matTooltip="Download file"
            (click)="downloadFile(element)"></button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</mat-card-content>

<mat-card-actions class="card-actions">
  <button
    mat-button
    color="primary"
    *ngIf="isAllowedToWrite"
    (click)="fileInput.click()">
    <span class="mdi mdi-upload"></span>
    <span>Upload file here</span>
    <input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display:none;" />
  </button>
  <button mat-button
    color="success"
    (click)="navigateRoot()"
    *ngIf="canNavigateUp"
  ><span class="mdi mdi-home"></span> Go to root folder</button>
</mat-card-actions>
