import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { FileElement } from '../file-explorer/model/element';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class FileDataService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  getInitContent(scope: string): Observable<any> {
    const dnn = this.sharedService.dnn || '';
    let url = `${environment.api.baseUrl}/actions/repository/files/${scope}`;
    if (dnn !== '') { url += `?dnn=${dnn}`; }
    return this.http.get<any[]>(url);
  }

  getContents(folder: string = null): Observable<any> {
    return this.http.post<any[]>(`${environment.api.baseUrl}/actions/repository/file/list`, { folder });
  }

  getBrokerTemplates(): Observable<any> {
    return this.http.get<any[]>(`${environment.api.baseUrl}/actions/repository/file/templates`);
  }

  getClientDocuments(): Observable<any> {
    const dnn = this.sharedService.dnn || '';
    return this.http.get<any[]>(`${environment.api.baseUrl}/actions/repository/file/documents/${dnn}`);
  }

  downloadFile(file: FileElement): Observable<any> {
    const data = {
      'name': file.name,
      'fileId': file.id,
      'mime': file.mime
    };
    return this.http.post(`${environment.api.baseUrl}/actions/repository/file/download`, data, {responseType: 'blob'});
  }

  uploadFile(file: any, currentFolder: string, scope: string): Observable<any> {
    const dnn = this.sharedService.dnn || '';
    const formData = new FormData();
    formData.append('folder', currentFolder);
    if (dnn !== '') { formData.append('dnn', dnn); }
    formData.append('scope', scope);
    formData.append('files', file, file['name']);

    return this.http.post<any[]>(`${environment.api.baseUrl}/actions/repository/file/upload`, formData);
  }
}
