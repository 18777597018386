import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this.visibility = new BehaviorSubject(false);
    }
    SpinnerService.prototype.setVisibility = function (status) {
        if (status === void 0) { status = false; }
        this.visibility.next(status);
    };
    SpinnerService.ngInjectableDef = i0.defineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };
