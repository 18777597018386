<div class="main-container">
  <mat-card class="card-content" *ngIf="isAuthenticated">
    <file-explorer
      [fileElements]="fileElements | async"
      [path]="currentPath"
      [alias]="alias"
      [scope]="scope"
      [isAllowedToWrite]="isAllowedToWrite"
      [canNavigateUp]="canNavigateUp"
      (downloadTriggered)="downloadElement($event)"
      (uploadTriggered)="upload($event)"
      (fileChanged)="fileChange($event)"
      (viewTriggered)="viewElement($event)"
      (folderAdded)="addFolder($event)"
      (elementRemoved)="removeElement($event)"
      (navigatedDown)="navigateToFolder($event)"
      (navigatedUp)="navigateUp($event)"
      (elementRenamed)="renameElement($event)"
      (elementMoved)="moveElement($event)">
    </file-explorer>
  </mat-card>
  <section class="mat-typography centered" *ngIf="!isAuthenticated">
    <span class="mdi mdi-cloud-off-outline huge"></span>
    <h2>Not authorized</h2>
    <h3>Please try to refresh or login again.</h3>
  </section>
  <ng-container *ngIf="blob_type">
    <div class="viewer">
      <iframe [src]="blobsrc" width='100%' height='100%'></iframe>
      <button mat-mini-fab color="accent" aria-label="Close" class="close-button" (click)="closeViewer()">
        <mat-icon>close_icon</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
<app-spinner
  class="full-spinner"
  *ngIf="spinnerService.visibility.value"
></app-spinner>
