import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';
import { FileElement } from './file-explorer/model/element';
import { Observable, Subscription } from 'rxjs';
import { FileService, FileDataService, SpinnerService, SharedService } from './services';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  public fileElements: Observable<FileElement[]>;
  private scope: string;
  private alias: string;
  isAuthenticated = false;
  canNavigateUp = false;
  isAllowedToWrite = true;
  blobsrc: any;
  blob_type: string;
  currentRoot: FileElement;
  currentPath: string;
  fileSize = 0;
  MAX_FILE_SIZE = 1300000;
  brokerTemplatesSubscription: Subscription;

  constructor(
    public fileService: FileService,
    private dataService: FileDataService,
    public spinnerService: SpinnerService,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
  ) {
    const params: any = window.location.search
      .slice(1)
      .split('&')
      .map(p => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    if ( params.token ) {
      sharedService.token = params.token;
      if (params.dnn) { sharedService.dnn = params.dnn; }
      this.scope = params.scope || 'employee';
      this.alias = params.alias || '';
      this.isAuthenticated = true;
    }
  }

  ngOnInit() {
    if (this.scope === 'broker') {
      if (this.sharedService.dnn) {
        this.getClientDocumentsContent();
      } else {
        this.getBrokerTemplatesContent();
      }
    } else {
      this.getInitContent();
    }
  }

  ngOnDestroy() {
    if (this.brokerTemplatesSubscription) {
      this.brokerTemplatesSubscription.unsubscribe();
    }
  }

  getInitContent() {
    this.dataService.getInitContent(this.scope)
      .subscribe(
        result => {
          const { message: { content = null } = {} } = result;
          if (this.scope !== 'benAdminClient') {
            this.isAllowedToWrite = result.message.isAllowedToWrite || false;
            this.batchInput(content);
          } else if (content.length) {
              window.location.href = `https://drive.google.com/drive/folders/${content}`;
          } else {
            this.snackBar.open('The folder does not exist', 'Ok', {
              verticalPosition: 'top',
              panelClass: ['mat-toolbar', 'mat-primary']
            });
          }
        },
        error => {
          console.log(error);
          this.isAuthenticated = false;
        }
      );
  }

  getBrokerTemplatesContent() {
    this.brokerTemplatesSubscription = this.dataService.getBrokerTemplates()
      .subscribe(result => {
        const { message: { content = null, folderName = 'Broker Template', isAllowedToWrite = false } = {} } = result;
        this.isAllowedToWrite = isAllowedToWrite;
        this.alias = folderName;
        this.batchInput(content);
      },
      error => {
        console.log(error);
        this.isAuthenticated = false;
      });
  }

  getClientDocumentsContent() {
    this.dataService.getInitContent(this.scope)
      .subscribe(
        result => {
          const { message: { content = null, folderName = 'Client', isAllowedToWrite = false } = {} } = result;
          this.isAllowedToWrite = isAllowedToWrite || false;
          this.alias = folderName;
          this.batchInput(content);
        },
        error => {
          console.log(error);
          this.isAuthenticated = false;
        }
      );
  }

  getFolderContent(folderID: string = null) {
    this.dataService.getContents(folderID)
      .subscribe(
        contents => this.batchInput(contents.message),
        error => {
          console.log(error);
          this.isAuthenticated = false;
        }
      );
  }

  batchInput(newContent: Array<any>) {
    if (newContent.length) {
      newContent.forEach(item => {
        const existItem = this.fileService.find(item.id);
        if (!existItem) {
          this.fileService.add({
            id: item.id,
            name: item.name,
            isFolder: (item.mimeType === 'application/vnd.google-apps.folder'),
            parent: (this.currentRoot ? this.currentRoot.id : 'root' ),
            mime: item.mimeType
          });
        }
      });
    }
    this.updateFileElementQuery();
  }

  addFolder(folder: { name: string }) {
    this.fileService.add({ isFolder: true, name: folder.name, parent: this.currentRoot ? this.currentRoot.id : null });
    this.updateFileElementQuery();
  }

  removeElement(element: FileElement) {
    this.fileService.delete(element.id);
    this.updateFileElementQuery();
  }

  navigateToFolder(element: FileElement) {
    this.currentRoot = element;
    if (this.fileService.find(element.id) ) {
      this.getFolderContent(element.id);
    }
    this.updateFileElementQuery();
    this.currentPath = this.pushToPath(this.currentPath, element.name);
    this.canNavigateUp = true;
  }

  navigateUp(toRoot: string = null) {
    if ((this.currentRoot && this .currentRoot.parent === 'root') || toRoot) {
      this.currentRoot = null;
      this.currentPath = null;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else {
      this.currentRoot = this.fileService.get(this.currentRoot.parent);
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  moveElement(event: { element: FileElement; moveTo: FileElement }) {
    this.fileService.update(event.element.id, { parent: event.moveTo.id });
    this.updateFileElementQuery();
  }

  renameElement(element: FileElement) {
    this.fileService.update(element.id, { name: element.name });
    this.updateFileElementQuery();
  }

  updateFileElementQuery() {
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '';
    p += `${folderName} / `;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    const split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  viewElement(element: FileElement) {
    this.dataService
      .downloadFile(element)
      .subscribe(response => {
        this.fileSize = response.size;
        const blob: any = new Blob([response], { type: element.mime });
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const parser = reader.result.toString();
          this.blobsrc = this.sanitizer.bypassSecurityTrustResourceUrl(parser);
          this.blob_type = element.mime;
        }, false);
        if (this.fileSize < this.MAX_FILE_SIZE) {
          reader.readAsDataURL(blob);
        } else {
          this.downloadElement(element);
        }
        this.fileSize = 0;
      });
  }

  downloadElement(element: FileElement) {
    this.dataService
      .downloadFile(element)
      .subscribe(response => {
        const blob: any = new Blob([response], { type: element.mime });
        fileSaver.saveAs(blob, element.name);
      });
  }

  closeViewer() {
    this.blob_type = null;
    this.blobsrc = null;
  }

  fileChange(evt) {
    const path = this.currentRoot
      ? this.currentRoot.id
      : 'root';
    this.dataService
      .uploadFile(evt[0], path, this.scope)
      .subscribe((response) => {
        this.currentRoot
          ? this.getFolderContent(this.currentRoot.id)
          : this.getInitContent();
        this.updateFileElementQuery();
      }, (error) => {
        console.log('error in fileupload', error);
      });
  }
}
