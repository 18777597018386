import { EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
var FileExplorerComponent = /** @class */ (function () {
    function FileExplorerComponent(dialog) {
        this.dialog = dialog;
        this.displayedColumns = ['name', 'actions'];
        this._alias = '';
        this.folderAdded = new EventEmitter();
        this.downloadTriggered = new EventEmitter();
        this.uploadTriggered = new EventEmitter();
        this.fileChanged = new EventEmitter();
        this.viewTriggered = new EventEmitter();
        this.elementRemoved = new EventEmitter();
        this.elementRenamed = new EventEmitter();
        this.elementMoved = new EventEmitter();
        this.navigatedDown = new EventEmitter();
        this.navigatedUp = new EventEmitter();
    }
    Object.defineProperty(FileExplorerComponent.prototype, "alias", {
        get: function () { return this._alias; },
        set: function (alias) {
            this._alias = this.titleHandler(alias);
        },
        enumerable: true,
        configurable: true
    });
    FileExplorerComponent.prototype.handleIframeHeight = function (event) {
        this.sendHeight();
    };
    FileExplorerComponent.prototype.ngOnInit = function () {
        this.sendHeight();
    };
    FileExplorerComponent.prototype.ngOnChanges = function (changes) {
        if (this.fileElements) {
            this.dataSource = new MatTableDataSource(this.fileElements);
            this.dataSource.sort = this.sort;
        }
        this.sendHeight();
    };
    FileExplorerComponent.prototype.sendHeight = function () {
        setTimeout(function () {
            var mainContainer = document.querySelector('.main-container');
            window.parent.postMessage(mainContainer.offsetHeight, '*');
        });
    };
    FileExplorerComponent.prototype.titleHandler = function (alias) {
        var title = decodeURIComponent(alias) || (this.scope || '');
        return title.charAt(0).toUpperCase() + title.slice(1) + ' Documents';
    };
    FileExplorerComponent.prototype.deleteElement = function (element) {
        this.elementRemoved.emit(element);
    };
    FileExplorerComponent.prototype.navigate = function (element) {
        if (element.isFolder) {
            this.navigatedDown.emit(element);
        }
    };
    FileExplorerComponent.prototype.navigateUp = function () {
        this.navigatedUp.emit();
    };
    FileExplorerComponent.prototype.navigateRoot = function () {
        this.navigatedUp.emit('root');
    };
    FileExplorerComponent.prototype.moveElement = function (element, moveTo) {
        this.elementMoved.emit({ element: element, moveTo: moveTo });
    };
    FileExplorerComponent.prototype.downloadFile = function (element) {
        this.downloadTriggered.emit(element);
    };
    FileExplorerComponent.prototype.viewFile = function (element) {
        if (element.name.includes('pdf')) {
            this.viewTriggered.emit(element);
        }
    };
    FileExplorerComponent.prototype.openMenu = function (event, viewChild, element) {
        event.preventDefault();
        if (!element.isFolder) {
            viewChild.openMenu();
        }
    };
    FileExplorerComponent.prototype.onFileInput = function ($evt) {
        this.fileChanged.emit($evt);
    };
    FileExplorerComponent.prototype.getIcon = function (mime) {
        var icon = '';
        switch (mime) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                icon = 'file-image-outline';
                break;
            case 'application/pdf':
                icon = 'file-pdf-box-outline';
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
                icon = 'file-excel-outline';
                break;
            case 'application/vnd.google-apps.folder':
                icon = 'folder';
                break;
            default:
                icon = 'file-document-outline';
                break;
        }
        return icon;
    };
    FileExplorerComponent.prototype.showTooltip = function (isDisabled) {
        return !isDisabled ? 'View file' : 'Only PDF files can be viewed.';
    };
    return FileExplorerComponent;
}());
export { FileExplorerComponent };
