/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./file-explorer/file-explorer.component.ngfactory";
import * as i5 from "./file-explorer/file-explorer.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/common";
import * as i8 from "../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "./components/spinner/spinner.component.ngfactory";
import * as i16 from "./services/spinner.service";
import * as i17 from "./components/spinner/spinner.component";
import * as i18 from "./app.component";
import * as i19 from "./services/file.service";
import * as i20 from "./services/file-data.service";
import * as i21 from "./services/shared.service";
import * as i22 from "@angular/platform-browser";
import * as i23 from "@angular/material/snack-bar";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-card", [["class", "card-content mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "file-explorer", [], null, [[null, "downloadTriggered"], [null, "uploadTriggered"], [null, "fileChanged"], [null, "viewTriggered"], [null, "folderAdded"], [null, "elementRemoved"], [null, "navigatedDown"], [null, "navigatedUp"], [null, "elementRenamed"], [null, "elementMoved"], ["window", "message"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleIframeHeight($event) !== false);
        ad = (pd_0 && ad);
    } if (("downloadTriggered" === en)) {
        var pd_1 = (_co.downloadElement($event) !== false);
        ad = (pd_1 && ad);
    } if (("uploadTriggered" === en)) {
        var pd_2 = (_co.upload($event) !== false);
        ad = (pd_2 && ad);
    } if (("fileChanged" === en)) {
        var pd_3 = (_co.fileChange($event) !== false);
        ad = (pd_3 && ad);
    } if (("viewTriggered" === en)) {
        var pd_4 = (_co.viewElement($event) !== false);
        ad = (pd_4 && ad);
    } if (("folderAdded" === en)) {
        var pd_5 = (_co.addFolder($event) !== false);
        ad = (pd_5 && ad);
    } if (("elementRemoved" === en)) {
        var pd_6 = (_co.removeElement($event) !== false);
        ad = (pd_6 && ad);
    } if (("navigatedDown" === en)) {
        var pd_7 = (_co.navigateToFolder($event) !== false);
        ad = (pd_7 && ad);
    } if (("navigatedUp" === en)) {
        var pd_8 = (_co.navigateUp($event) !== false);
        ad = (pd_8 && ad);
    } if (("elementRenamed" === en)) {
        var pd_9 = (_co.renameElement($event) !== false);
        ad = (pd_9 && ad);
    } if (("elementMoved" === en)) {
        var pd_10 = (_co.moveElement($event) !== false);
        ad = (pd_10 && ad);
    } return ad; }, i4.View_FileExplorerComponent_0, i4.RenderType_FileExplorerComponent)), i1.ɵdid(3, 638976, null, 0, i5.FileExplorerComponent, [i6.MatDialog], { fileElements: [0, "fileElements"], canNavigateUp: [1, "canNavigateUp"], path: [2, "path"], scope: [3, "scope"], isAllowedToWrite: [4, "isAllowedToWrite"], alias: [5, "alias"] }, { folderAdded: "folderAdded", downloadTriggered: "downloadTriggered", uploadTriggered: "uploadTriggered", fileChanged: "fileChanged", viewTriggered: "viewTriggered", elementRemoved: "elementRemoved", elementRenamed: "elementRenamed", elementMoved: "elementMoved", navigatedDown: "navigatedDown", navigatedUp: "navigatedUp" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.fileElements)); var currVal_1 = _co.canNavigateUp; var currVal_2 = _co.currentPath; var currVal_3 = _co.scope; var currVal_4 = _co.isAllowedToWrite; var currVal_5 = _co.alias; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "mat-typography centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "mdi mdi-cloud-off-outline huge"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not authorized"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please try to refresh or login again."]))], null, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "viewer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "iframe", [["height", "100%"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["aria-label", "Close"], ["class", "close-button"], ["color", "accent"], ["mat-mini-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeViewer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null], [2, i14.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close_icon"]))], function (_ck, _v) { var currVal_3 = "accent"; _ck(_v, 4, 0, currVal_3); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blobsrc; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 4).disabled || null); var currVal_2 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 6).inline; var currVal_5 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_4, currVal_5); }); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-spinner", [["class", "full-spinner"]], null, null, null, i15.View_SpinnerComponent_0, i15.RenderType_SpinnerComponent)), i1.ɵprd(4608, null, i16.SpinnerService, i16.SpinnerService, []), i1.ɵdid(2, 114688, null, 0, i17.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAuthenticated; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isAuthenticated; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.blob_type; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.spinnerService.visibility.value; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i18.AppComponent, [i19.FileService, i20.FileDataService, i16.SpinnerService, i21.SharedService, i22.DomSanitizer, i23.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
