/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../services/spinner.service";
import * as i10 from "./spinner.component";
var styles_SpinnerComponent = [i0.styles];
var RenderType_SpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerComponent, data: {} });
export { RenderType_SpinnerComponent as RenderType_SpinnerComponent };
export function View_SpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "full-layout"], ["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-spinner", [["class", "centered mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i4.View_MatProgressSpinner_0, i4.RenderType_MatProgressSpinner)), i1.ɵdid(3, 49152, null, 0, i5.MatProgressSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_8 = "indeterminate"; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 3).diameter; var currVal_3 = i1.ɵnov(_v, 3).diameter; var currVal_4 = ((i1.ɵnov(_v, 3).mode === "determinate") ? 0 : null); var currVal_5 = ((i1.ɵnov(_v, 3).mode === "determinate") ? 100 : null); var currVal_6 = i1.ɵnov(_v, 3).value; var currVal_7 = i1.ɵnov(_v, 3).mode; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_SpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-spinner", [], null, null, null, View_SpinnerComponent_0, RenderType_SpinnerComponent)), i1.ɵprd(4608, null, i9.SpinnerService, i9.SpinnerService, []), i1.ɵdid(2, 114688, null, 0, i10.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SpinnerComponentNgFactory = i1.ɵccf("app-spinner", i10.SpinnerComponent, View_SpinnerComponent_Host_0, {}, {}, []);
export { SpinnerComponentNgFactory as SpinnerComponentNgFactory };
