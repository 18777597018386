var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';
import { FileService, FileDataService, SpinnerService, SharedService } from './services';
import * as fileSaver from 'file-saver';
var AppComponent = /** @class */ (function () {
    function AppComponent(fileService, dataService, spinnerService, sharedService, sanitizer, snackBar) {
        this.fileService = fileService;
        this.dataService = dataService;
        this.spinnerService = spinnerService;
        this.sharedService = sharedService;
        this.sanitizer = sanitizer;
        this.snackBar = snackBar;
        this.isAuthenticated = false;
        this.canNavigateUp = false;
        this.isAllowedToWrite = true;
        this.fileSize = 0;
        this.MAX_FILE_SIZE = 1300000;
        var params = window.location.search
            .slice(1)
            .split('&')
            .map(function (p) { return p.split('='); })
            .reduce(function (obj, _a) {
            var key = _a[0], value = _a[1];
            var _b;
            return (__assign({}, obj, (_b = {}, _b[key] = value, _b)));
        }, {});
        if (params.token) {
            sharedService.token = params.token;
            if (params.dnn) {
                sharedService.dnn = params.dnn;
            }
            this.scope = params.scope || 'employee';
            this.alias = params.alias || '';
            this.isAuthenticated = true;
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        if (this.scope === 'broker') {
            if (this.sharedService.dnn) {
                this.getClientDocumentsContent();
            }
            else {
                this.getBrokerTemplatesContent();
            }
        }
        else {
            this.getInitContent();
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.brokerTemplatesSubscription) {
            this.brokerTemplatesSubscription.unsubscribe();
        }
    };
    AppComponent.prototype.getInitContent = function () {
        var _this = this;
        this.dataService.getInitContent(this.scope)
            .subscribe(function (result) {
            var _a = result.message, _b = (_a === void 0 ? {} : _a).content, content = _b === void 0 ? null : _b;
            if (_this.scope !== 'benAdminClient') {
                _this.isAllowedToWrite = result.message.isAllowedToWrite || false;
                _this.batchInput(content);
            }
            else if (content.length) {
                window.location.href = "https://drive.google.com/drive/folders/" + content;
            }
            else {
                _this.snackBar.open('The folder does not exist', 'Ok', {
                    verticalPosition: 'top',
                    panelClass: ['mat-toolbar', 'mat-primary']
                });
            }
        }, function (error) {
            console.log(error);
            _this.isAuthenticated = false;
        });
    };
    AppComponent.prototype.getBrokerTemplatesContent = function () {
        var _this = this;
        this.brokerTemplatesSubscription = this.dataService.getBrokerTemplates()
            .subscribe(function (result) {
            var _a = result.message, _b = _a === void 0 ? {} : _a, _c = _b.content, content = _c === void 0 ? null : _c, _d = _b.folderName, folderName = _d === void 0 ? 'Broker Template' : _d, _e = _b.isAllowedToWrite, isAllowedToWrite = _e === void 0 ? false : _e;
            _this.isAllowedToWrite = isAllowedToWrite;
            _this.alias = folderName;
            _this.batchInput(content);
        }, function (error) {
            console.log(error);
            _this.isAuthenticated = false;
        });
    };
    AppComponent.prototype.getClientDocumentsContent = function () {
        var _this = this;
        this.dataService.getInitContent(this.scope)
            .subscribe(function (result) {
            var _a = result.message, _b = _a === void 0 ? {} : _a, _c = _b.content, content = _c === void 0 ? null : _c, _d = _b.folderName, folderName = _d === void 0 ? 'Client' : _d, _e = _b.isAllowedToWrite, isAllowedToWrite = _e === void 0 ? false : _e;
            _this.isAllowedToWrite = isAllowedToWrite || false;
            _this.alias = folderName;
            _this.batchInput(content);
        }, function (error) {
            console.log(error);
            _this.isAuthenticated = false;
        });
    };
    AppComponent.prototype.getFolderContent = function (folderID) {
        var _this = this;
        if (folderID === void 0) { folderID = null; }
        this.dataService.getContents(folderID)
            .subscribe(function (contents) { return _this.batchInput(contents.message); }, function (error) {
            console.log(error);
            _this.isAuthenticated = false;
        });
    };
    AppComponent.prototype.batchInput = function (newContent) {
        var _this = this;
        if (newContent.length) {
            newContent.forEach(function (item) {
                var existItem = _this.fileService.find(item.id);
                if (!existItem) {
                    _this.fileService.add({
                        id: item.id,
                        name: item.name,
                        isFolder: (item.mimeType === 'application/vnd.google-apps.folder'),
                        parent: (_this.currentRoot ? _this.currentRoot.id : 'root'),
                        mime: item.mimeType
                    });
                }
            });
        }
        this.updateFileElementQuery();
    };
    AppComponent.prototype.addFolder = function (folder) {
        this.fileService.add({ isFolder: true, name: folder.name, parent: this.currentRoot ? this.currentRoot.id : null });
        this.updateFileElementQuery();
    };
    AppComponent.prototype.removeElement = function (element) {
        this.fileService.delete(element.id);
        this.updateFileElementQuery();
    };
    AppComponent.prototype.navigateToFolder = function (element) {
        this.currentRoot = element;
        if (this.fileService.find(element.id)) {
            this.getFolderContent(element.id);
        }
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    };
    AppComponent.prototype.navigateUp = function (toRoot) {
        if (toRoot === void 0) { toRoot = null; }
        if ((this.currentRoot && this.currentRoot.parent === 'root') || toRoot) {
            this.currentRoot = null;
            this.currentPath = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        }
        else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    };
    AppComponent.prototype.moveElement = function (event) {
        this.fileService.update(event.element.id, { parent: event.moveTo.id });
        this.updateFileElementQuery();
    };
    AppComponent.prototype.renameElement = function (element) {
        this.fileService.update(element.id, { name: element.name });
        this.updateFileElementQuery();
    };
    AppComponent.prototype.updateFileElementQuery = function () {
        this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
    };
    AppComponent.prototype.pushToPath = function (path, folderName) {
        var p = path ? path : '';
        p += folderName + " / ";
        return p;
    };
    AppComponent.prototype.popFromPath = function (path) {
        var p = path ? path : '';
        var split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    };
    AppComponent.prototype.viewElement = function (element) {
        var _this = this;
        this.dataService
            .downloadFile(element)
            .subscribe(function (response) {
            _this.fileSize = response.size;
            var blob = new Blob([response], { type: element.mime });
            var reader = new FileReader();
            reader.addEventListener('load', function () {
                var parser = reader.result.toString();
                _this.blobsrc = _this.sanitizer.bypassSecurityTrustResourceUrl(parser);
                _this.blob_type = element.mime;
            }, false);
            if (_this.fileSize < _this.MAX_FILE_SIZE) {
                reader.readAsDataURL(blob);
            }
            else {
                _this.downloadElement(element);
            }
            _this.fileSize = 0;
        });
    };
    AppComponent.prototype.downloadElement = function (element) {
        this.dataService
            .downloadFile(element)
            .subscribe(function (response) {
            var blob = new Blob([response], { type: element.mime });
            fileSaver.saveAs(blob, element.name);
        });
    };
    AppComponent.prototype.closeViewer = function () {
        this.blob_type = null;
        this.blobsrc = null;
    };
    AppComponent.prototype.fileChange = function (evt) {
        var _this = this;
        var path = this.currentRoot
            ? this.currentRoot.id
            : 'root';
        this.dataService
            .uploadFile(evt[0], path, this.scope)
            .subscribe(function (response) {
            _this.currentRoot
                ? _this.getFolderContent(_this.currentRoot.id)
                : _this.getInitContent();
            _this.updateFileElementQuery();
        }, function (error) {
            console.log('error in fileupload', error);
        });
    };
    return AppComponent;
}());
export { AppComponent };
