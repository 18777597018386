import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiKeyInterceptor } from './interceptors/api-key';
import { FileService, FileDataService, SpinnerService, SharedService } from './services';
import { FileExplorerModule } from './file-explorer/file-explorer.module';
import { SpinnerComponent } from './components';

@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  imports: [
    BrowserModule,
    FileExplorerModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatCardModule,
    MatProgressSpinnerModule,
    HttpClientModule],
  providers: [
    FileService,
    FileDataService,
    SpinnerService,
    SharedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule {}
