import { BehaviorSubject } from 'rxjs';
var FileService = /** @class */ (function () {
    function FileService() {
        this.map = new Map();
    }
    FileService.prototype.add = function (fileElement) {
        this.map.set(fileElement.id, this.clone(fileElement));
        return fileElement;
    };
    FileService.prototype.delete = function (id) {
        this.map.delete(id);
    };
    FileService.prototype.update = function (id, update) {
        var element = this.map.get(id);
        element = Object.assign(element, update);
        this.map.set(element.id, element);
    };
    FileService.prototype.queryInFolder = function (folderId) {
        var _this = this;
        var result = [];
        this.map.forEach(function (element) {
            if (element.parent === folderId) {
                result.push(_this.clone(element));
            }
        });
        if (!this.querySubject) {
            this.querySubject = new BehaviorSubject(result);
        }
        else {
            this.querySubject.next(result);
        }
        return this.querySubject.asObservable();
    };
    FileService.prototype.get = function (id) {
        return this.map.get(id);
    };
    FileService.prototype.clone = function (element) {
        return JSON.parse(JSON.stringify(element));
    };
    FileService.prototype.find = function (id) {
        return this.map.has(id);
    };
    return FileService;
}());
export { FileService };
