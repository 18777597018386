import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { FileElement } from './model/element';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.css']
})
export class FileExplorerComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['name', 'actions'];
  constructor(public dialog: MatDialog) {}

  @Input() fileElements: FileElement[];
  @Input() canNavigateUp: string;
  @Input() path: string;
  @Input() scope: string;
  @Input() isAllowedToWrite: boolean;
  @Input()
  get alias(): string { return this._alias; }
  set alias(alias: string) {
    this._alias = this.titleHandler(alias);
  }
  private _alias = '';

  @Output() folderAdded       = new EventEmitter<{ name: string }>();
  @Output() downloadTriggered = new EventEmitter<FileElement>();
  @Output() uploadTriggered   = new EventEmitter<any>();
  @Output() fileChanged       = new EventEmitter<any>();
  @Output() viewTriggered     = new EventEmitter<FileElement>();
  @Output() elementRemoved    = new EventEmitter<FileElement>();
  @Output() elementRenamed    = new EventEmitter<FileElement>();
  @Output() elementMoved      = new EventEmitter<{ element: FileElement; moveTo: FileElement }>();
  @Output() navigatedDown     = new EventEmitter<FileElement>();
  @Output() navigatedUp       = new EventEmitter<string>();

  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<FileElement>;
  @HostListener('window:message', ['$event'])
  handleIframeHeight(event) {
    this.sendHeight();
  }

  ngOnInit() {
    this.sendHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fileElements ) {
      this.dataSource = new MatTableDataSource(this.fileElements);
      this.dataSource.sort = this.sort;
    }
    this.sendHeight();
  }

  sendHeight(): void {
    setTimeout(() => {
      const mainContainer = document.querySelector('.main-container') as HTMLElement;
      window.parent.postMessage(mainContainer.offsetHeight, '*');
    });
  }

  titleHandler(alias: string) {
    const title = decodeURIComponent(alias) || (this.scope || '');
    return title.charAt(0).toUpperCase() + title.slice(1) + ' Documents';
  }

  deleteElement(element: FileElement) {
    this.elementRemoved.emit(element);
  }

  navigate(element: FileElement) {
    if (element.isFolder) {
      this.navigatedDown.emit(element);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  navigateRoot() {
    this.navigatedUp.emit('root');
  }

  moveElement(element: FileElement, moveTo: FileElement) {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  }

  downloadFile(element: FileElement) {
    this.downloadTriggered.emit(element);
  }

  viewFile(element: FileElement) {
    if (element.name.includes('pdf')) {
      this.viewTriggered.emit(element);
    }
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger, element: FileElement) {
    event.preventDefault();
    if (!element.isFolder) {
      viewChild.openMenu();
    }
  }

  onFileInput($evt) {
    this.fileChanged.emit($evt);
  }

  getIcon(mime) {
    let icon = '';
    switch (mime ) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        icon = 'file-image-outline';
        break;
      case 'application/pdf':
        icon = 'file-pdf-box-outline';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        icon = 'file-excel-outline';
        break;
      case 'application/vnd.google-apps.folder':
        icon = 'folder';
        break;
      default:
        icon = 'file-document-outline';
        break;
    }
    return icon;
  }

  showTooltip(isDisabled: boolean): string {
    return !isDisabled ? 'View file' : 'Only PDF files can be viewed.';
  }

}
