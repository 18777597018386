import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SpinnerService, SharedService } from './../services';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiKeyInterceptor implements HttpInterceptor {

  constructor(
    private spinnerService: SpinnerService,
    private sharedService: SharedService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.setVisibility(true);
    const token = this.sharedService.token;
    return next.handle(request.clone({
        setHeaders: {
            'api-key': environment.api.key,
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
        }
    }))
    .pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            this.spinnerService.setVisibility(false);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinnerService.setVisibility(false);
        return throwError(error);
      })
    );
  }
}
